import { NavigationGuard } from "vue-router";
import api from '@/api/Scraping'
import { ref, reactive } from 'vue'

export { accessGuard_Method, accessGuard_Confirm, accessGuard_Result }

/* router Guard - Method */
const accessGuard_Method: NavigationGuard = async (to, from, next) => {
    const { query } = to;

    if (query.receiptId) {
        const existsResult = reactive<ExistsResult>(Object.create(null));
        const pass = ref<boolean>(false);

        Object.assign(existsResult, await CheckReceiptId(query.receiptId.toString(), "request"));
        pass.value = existsResult.isExistsRequest && !existsResult.isExistsRequestLog;

        pass.value ? next()
        : !existsResult.isExistsRequest ? invalidAccess('잘못된 접수번호입니다.')
        : existsResult.isExistsRequestLog ? invalidAccess('이미 완료된 요청입니다.')
        : invalidAccess();
    } else {
        invalidAccess();
    }
};

/* router Guard - Confirm */
const accessGuard_Confirm: NavigationGuard = async (to, from, next) => {
    const { query } = to;

    if (from.name === 'method') {
        next();
    } else if (query.receiptId) {
        next({ name: 'method', query });
    } else {
        invalidAccess();
    }
}

/* router Guard - Reulst */
const accessGuard_Result: NavigationGuard = async (to, from, next) => {
    const { query } = to;

    if (from.name === 'confirm') {
        const receiptId = from.query.receiptId?.toString() || '';
        const existsResult = reactive<ExistsResult>(Object.create(null));

        Object.assign(existsResult, await CheckReceiptId(receiptId, "result"));
        existsResult.isExistsRequest ? next() : invalidAccess('잘못된 접수번호입니다.');
    } else if (query.receiptId) {
        next({ name: 'method', query });
    } else {
        invalidAccess();
    }
}

/* Alert */
const invalidAccess = (msg = '잘못된 접근입니다.') => {
    alert(msg);
    window.history.back();
};

/* 접수번호 체크 */
const CheckReceiptId = async (receiptId: string, type: string) => {
const result = await api.CheckReceiptId(receiptId.toString(), type).then((res: ValidReceiptIdResult) => {
    return res.data;
}).catch((error: Error) => {
    console.log("%cError Name : %s", "background: ed;", error.name);
    console.log("%cError Stack : %s", "background: red;", error.stack);
    console.log("%cError Message : %s", "background: red;", error.message);
});

return result;
};


  /* Backup
  
    const accessGuard: NavigationGuard = async (to, from, next) => {
    const { name, query } = to;

    if (name === 'method') {
        if (query.receiptId) {
        const existsResult = reactive<ExistsResult>(Object.create(null));
        const pass = ref<boolean>(false);

        Object.assign(existsResult, await CheckReceiptId(query.receiptId.toString(), "request"));
        pass.value = existsResult.isExistsRequest && !existsResult.isExistsRequestLog;

        pass.value ? next()
            : !existsResult.isExistsRequest ? invalidAccess('잘못된 접수번호입니다.')
            : existsResult.isExistsRequestLog ? invalidAccess('이미 완료된 요청입니다.')
            : invalidAccess();
        } else {
        invalidAccess();
        }
    } else if (name === 'confirm') {
        if (from.name === 'method') {
        next();
        } else if (query.receiptId) {
        next({ name: 'method', query });
        } else {
        invalidAccess();
        }
    } else if (name === 'result') {
        if (from.name === 'confirm') {
        const receiptId = from.query.receiptId?.toString() || '';
        const existsResult = reactive<ExistsResult>(Object.create(null));
        Object.assign(existsResult, await CheckReceiptId(receiptId, "result"));
        existsResult.isExistsRequest ? next() : invalidAccess('잘못된 접수번호입니다.');
        } else if (query.receiptId) {
        next({ name: 'method', query });
        } else {
        invalidAccess();
        }
    } else {
        next();
    }
    };
*/