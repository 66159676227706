import { ref } from 'vue';
import { get, post } from './index'

const isTest = ref<boolean>(process.env.NODE_ENV === "production");
const env = ref<string>('development');
const devUrl = ref<string>('https://scraping-apidev.tobecon.io/');
const prdUrl = ref<string>('https://scraping-api.tobecon.io/');

export default {
    TreatRequest(data: RequestParameter): Promise<ScrapingResult> {
        return post<ScrapingResult>({
            headers : { "TOBECON-AUTH" : "9052e1eca3204473acf644de50c326ab" },
            baseURL: isTest.value ? env.value == 'development' ? devUrl.value : prdUrl.value : '',
            url: '/samsungfire/api/reimburse/request',
            data: data
        })
    },

    TreatConfirm(receiptId: string): Promise<ScrapingResult> {
        return get<ScrapingResult>({
            headers : { "TOBECON-AUTH" : "9052e1eca3204473acf644de50c326ab" },
            baseURL: isTest.value ? env.value == 'development' ? devUrl.value : prdUrl.value : '',
            url: `/samsungfire/api/reimburse/confirm?receiptId=${encodeURIComponent(receiptId)}`
        })
    },

    CheckReceiptId(receiptId: string, type: string): Promise<ValidReceiptIdResult> {
        return get<ValidReceiptIdResult>({
            baseURL: isTest.value ? env.value == 'development' ? devUrl.value : prdUrl.value : '',
            url: `/samsungfire/api/reimburse/check?receiptId=${encodeURIComponent(receiptId)}&type=${type}`
        })
    },
}