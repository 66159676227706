import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { accessGuard_Method, accessGuard_Confirm, accessGuard_Result } from './accessGuard'
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/scraping/method"
  },
  {
    path: '/scraping',
    name: 'scraping',
    component: () => import(/* webpackChunkName: "MainView" */ '../views/MainView.vue'),
    children: [
      {
        path: 'method',
        name: 'method',
        component: () => import(/* webpackChunkName: "Method" */ '../components/organisms/Scraping/Method.vue'),
        beforeEnter: accessGuard_Method
      },
      {
        path: 'confirm',
        name: 'confirm',
        component: () => import(/* webpackChunkName: "Confirm" */ '../components/organisms/Scraping/Confirm.vue'),
        beforeEnter: accessGuard_Confirm
      },
      {
        path: 'result',
        name: 'result',
        component: () => import(/* webpackChunkName: "Result" */ '../components/organisms/Scraping/Result.vue'),
        // beforeEnter: accessGuard_Result
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;